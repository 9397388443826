<template>
  <div class="bg-secondary rounded p-6 mb-8 mt-4">
    <v-row>
      <v-col cols="auto">
        <v-row class="bg-secondary rounded mt-0">
          <v-col cols="auto" class="font-weight-black py-0 pr-0">
            Damaged:
          </v-col>
          <v-col class="py-0 mb-2">
            <template v-if="data?.is_damaged === null">-</template>
            <template v-if="data?.is_damaged === 0">No</template>
            <template v-if="data?.is_damaged === 1">Yes</template>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="auto">
        <v-row class="bg-secondary rounded mt-0">
          <v-col cols="auto" class="font-weight-black py-0 pr-0">
            Damage remark:
          </v-col>
          <v-col class="py-0 mb-2">{{ data?.damage_remark || "-" }}</v-col>
        </v-row>
      </v-col>
      <v-col cols="auto">
        <v-row class="bg-secondary rounded mt-0">
          <v-col cols="auto" class="font-weight-black py-0 pr-0">
            Damage reason code:
          </v-col>
          <v-col class="py-0 mb-2">{{ data?.damage_reason_code || "-" }}</v-col>
        </v-row>
      </v-col>
      <!-- <v-col cols="auto">
        <v-row class="bg-secondary rounded mt-0">
          <v-col cols="auto" class="font-weight-black py-0 pr-0">
            Suggested location:
          </v-col>
          <v-col class="py-0">{{ info?.suggested_location || "-" }}</v-col>
        </v-row>
      </v-col> -->
    </v-row>
  </div>
</template>

<script>
export default {
  name: "ItemDetails",
  computed: {
    data: function () {
      return this.$store.getters.getSTANDARDPUTAWAYSuggestedDetails;
    },
  },
};
</script>
